.wrapper {
  display: grid;
  justify-items: start;
  grid-gap: 10px;
  margin: 0px 0;
  text-align: center;
  position: relative;
  @media only screen and (min-width: $mobile_breakpoint) {
    margin: 20px 20px 0;
		max-width: $readable_length_max;
    text-align: left;
  }
}
header, footer {
  grid-column: 1 / -1;
  /* needed for the floated layout */
  clear: both;
}
.hidden {
  display: none;
}