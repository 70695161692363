@import "@fontsource/vt323";
@import "@fontsource/ibm-plex-sans/500.css";
@import "@fontsource/ibm-plex-sans/600.css";
@import "@fontsource/ibm-plex-sans/700.css";

:root {
  --screen-bg-blue: #F5F6FC;
  --neon-green: #1AC61A;
  --computer-blue: #27336F;
  --computer-blue-alt: #384AA1;
  --washi-light-blue: #46BADA;
  --washi-pink: #aca0a4;
  --washi-orange: #DB7C46;
}

$mobile_breakpoint: 560px;
$mobile_breakpoint: 680px;
// $mobile_breakpoint: 768px;
$ipad_breakpoint: 768px;

$readable_length_max: 960px;


$computer-font: 'VT323', monospace;