//profile


.content {
  @media only screen and (min-width: $mobile_breakpoint) {
    display: grid;
		grid-template-columns: 300px 2fr;
		grid-gap: 20px;
		max-width: $readable_length_max;
		margin: 20px 0 50px;
	}
  @media only screen and (min-width: $readable_length_max) {
    grid-gap: 10px;
		grid-template-columns: 1fr;
  }
}
.profile_photo {
  display: block;
  position: relative;
  display: grid;
  justify-items: center;
  // background-color: black;
  // padding-top: 105%;
  width: 100%;
  // height: 0;
  padding: 0;
  overflow: hidden;
  aspect-ratio: 2 / 3;
  aspect-ratio: 1 / 1;
  margin: 0 auto;
  max-width: 300px;
  @media only screen and (min-width: $mobile_breakpoint) {
    float: left;
    margin: 0 20px 10px 0;
  }
  @media only screen and (min-width: $readable_length_max) {
    width: 400px;
  }
  // border-bottom-left-radius: 40px;
  // border-bottom-right-radius: 40px;
  // border: 3px solid red;
  // border-width: 0 3px 3px 3px;
  img {
    // display: none;
    width: 100%;
    object-fit: contain;
  }
}
// .profile_photo .modal-open-button {
//   position: absolute;
//   top: 10px;
//   right: 0;
//   display: inline-block;
//   text-decoration: none;
//   padding: 0 4px;
//   border-radius: 50%;
//   text-align: center;
//   font-weight: 700;
//   font-family: 'VT323', monospace;
//   font-family: $computer-font;
//   font-size: .875em;
//   line-height: 1;
//   border: 2px solid var(--computer-blue);
//   color: var(--computer-blue);
// }
// .profile_photo .modal-open-button:hover {
//   border: 2px solid var(--washi-pink);
//   color: var(--washi-pink);
// }
.intro {
  display: grid;
  padding: 0 20px;
  // text-align: left;
  @media only screen and (min-width: $mobile_breakpoint) {
    padding: 0;
    grid-column: 1 / -1;
  }
}
.employers {
  margin: 30px 0;
}
.employers-list {
  text-align: left;

}
@media only screen and (min-width: $mobile_breakpoint) {
  .contact {
    grid-column: 2 / -1;
  }
  .profile_photo .modal-open-button {
    right: auto;
    left: 0;
  }
  .employers {
    grid-column: 1 / -1;
  }
}

.employers-list {
  @media only screen and (min-width: $ipad_breakpoint) {
    display: grid;
    grid-template-columns: 2fr 2fr;
    grid-gap: 10px;
  }
  li {
    position: relative;
    margin: 10px 15px 15px 0;
    padding: 10px;
    background-color: white;
    border: 4px solid var(--computer-blue-alt);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    &.volunteer {
      border-color: var(--washi-light-blue);
      &:after {
        background-color: darken(#46BADA, 15);
      }
    }
    &:after {
      content: "";
      z-index: -1;
      position: absolute;
      top: 8px;
      left: 8px;
      display: block;
      width: 100%;
      height: 100%;
      background-color: var(--computer-blue);
    }
    h5 {
      grid-column: 1 / -1;
    }
    h4 {
      text-align: right;
    }
  }
}


.skills {
  margin: 20px 15px 30px 0;
  grid-column: 1 / -1;
}
.skills-list {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  background-color: white;
  border: 4px solid red;
  position: relative;
  &:after {
    content: "";
    z-index: -1;
    position: absolute;
    top: 8px;
    left: 8px;
    display: block;
    width: 100%;
    height: 100%;
    background-color: darken(red, 20);
  }
  li {
   margin: 5px 5px 5px 30px;
   font-size: 1.1rem;
   list-style-type: '💾';
   &.video {
     list-style-type: '🎬';
   }
   &.design {
     list-style-type: '🖼';
   }
   &.disabled {
     list-style-type: '☠️';
   }
 }
}

.contact {
  margin: 20px auto 0;
  grid-column: 1 / -1;
}
.contact li, .contact div {
  font-family: $computer-font;
  font-weight: normal;
  text-transform: uppercase;
  // letter-spacing: 2px;
  font-size: 1.4rem;
}
// .contact a {
//   text-decoration: none;
//   background-color: var(--screen-bg-blue);
//   border: 4px solid var(--neon-green);
//   display: inline-block;
//   padding: 5px 15px;
//   line-height: 1;
//   transition: all .3s ease-in-out;
//   color:green;
//   color: var(--neon-green);
// }
// .contact a:hover {
//   background-color: var(--neon-green);
//   color: white;
// }
.washi-tape {
  position: fixed;
  top: 0;
  left: 0;

  background-color: var(--computer-blue);
  width: 100vw;
  height: 60px;
  overflow: hidden;
}
.washi-tape.start {
  animation: open_page 1s;
}
.washi-tape.slideUp {
  animation: open_modal .5s;
  height: 100vh;
}
@keyframes open_page {
  from {
    height: 100vh; 
  }

  to {
    height: 60px;
  }
}
@keyframes open_modal {
  from {
    height: 60px; 
  }

  to {
    height: 100vh;
  }
}

@media only screen and (min-width: $mobile_breakpoint) {
  .washi-tape {
    top: auto;
    bottom: 0;
  }
}
.pi-link {
  opacity: .1;
  position: absolute;
  bottom: 5px;
  right: 5px;
  cursor: pointer;
  color: black;
  font-weight: 700;
}


.modal-overlay {
  display: block;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.modal {
  z-index: 1010;
  position: fixed;
  max-width: 100%;
  max-height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: var(--computer-blue);
}
.closed {
  display: none;
}
@media only screen and (min-width: $mobile_breakpoint) {
  .modal {
    width: 95%;
    height: 85%;
  }
}
.modal-close-button {
  outline: none;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 20px;
  right: 20px;
  border: 2px solid var(--washi-light-blue);
  background-color: var(--washi-light-blue);
  color: var(--computer-blue);
  padding: 5px 10px;
  font-size: 1.5rem;
  font-weight: 700;
  font-family: $computer-font;
}
.modal-close-button:hover {
  background-color: var(--computer-blue);
  color: var(--washi-light-blue);

}
.modal-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px 50px 20px 20px;
  overflow: auto;
  color: white;
}
.modal-content a {
  color: var(--washi-light-blue);
}
.modal-content a:hover {
  color: white;
}
@media only screen and (min-width: $mobile_breakpoint) {
  .photo_credit_info_content {
    display: grid;
    grid-template-columns: 2fr 2fr;
    grid-gap: 10px;
  }
}
.photo_credit_info_content h2 {
  display: block;
  grid-column: 1 / -1;
}
.photo_credit_info_content > div {
  /* width: 75%; */
  margin: 20px auto;
  display: grid;
  justify-items: center;
  font-size: .875em;
}
.photo_credit_info_content .profile {
  width: 200px;
}
.contact-form {
  p {
    text-align: left;
    &.checkbox {
      label, input {
        display: inline-block;
        width: auto;
      }
    }
  }
  label, input, textarea {
    display: block;
  }
  input, textarea {
    width: 100%;
    padding: 10px 7px;
    @media only screen and (min-width: $mobile_breakpoint) {
      width: 400px;
    }
  }
  button {
    padding: 7px 15px; 
    background:#43b649; 
    border:0 none;
    cursor:pointer;
    -webkit-border-radius: 5px;
    border-radius: 0; 
    color: white;
    font-size: 1.5rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-family: 'VT323';
  }
}
.form-success {
  background-color: var(--computer-blue);
  color: white;
  padding: 10px 7px;
  h2 {
    margin: 0;
    padding: 0;
  }
  .name {
    color: var(--neon-green);
  }
}