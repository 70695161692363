
h3 {
  padding: 0;
  margin: 0;
  font-size: 1.35em;
}
h4 {
  padding: 0;
  margin: 0;
  font-weight: 600;
  font-size: 1.25em;
}
h5 {
  padding: 0;
  margin: 0;
  font-size: 1em;
  font-weight: 500;
}
h6 {
  padding: 0;
  margin: 0;
  font-family: $computer-font;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 1.4em;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
p {
  font-size: 1.35em;
  line-height: 1.4;
  font-weight: 500;
}
@media only screen and (min-width: $mobile_breakpoint) {
  p {
    // line-height: 1.8;
  }
}
a {
  color: var(--washi-orange);
}
a:hover {
  color: var(--washi-pink);
}
img {
  max-width: 100%;
}
.svg {
  display: block;
  width: 600px;

}
h2.section-title {
  margin: 0 0 10px;
  font-family: $computer-font;
  font-weight: normal;
  font-size: 2rem;
  text-transform: uppercase;
  letter-spacing: 2px;
}