.branding {
  width: 100%;
  a {
    text-decoration: none;
    color: inherit;
  }
}
h1.name {
  padding: 0;
  margin: 0;
  font-family: 'IBM Plex Sans', sans-serif;
  letter-spacing: -1px;
  font-size: 4em;
  font-size: 3.75em;
  line-height: 1;
  font-weight: 700;
  color: var(--computer-blue);
  color: var(--neon-green);
  text-transform: uppercase;
  text-align: center;
  @media only screen and (min-width: $mobile_breakpoint) {
    font-size: 3em;
    text-align: left;
  }
  @media only screen and (min-width: 800px) {
    font-size: 4em;
  }
}
span.irish_surname {
  display: block;
  @media only screen and (min-width: $mobile_breakpoint) {
    display: inline-block;
  }
  .lower-c {
    display: inline-block;
    position: relative;
    vertical-align: top;
    margin: 3px 0 0;
    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: -2px;
      left: 2px;
      width: 100%;
      height: 3px;
      background-color: var(--computer-blue);
    }
  }
}

/* title */
h2.title {
  padding: 0;
  margin: 0 auto;
  font-family: $computer-font;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 1.65em;
  font-weight: normal;
  @media only screen and (min-width: $mobile_breakpoint) {
    font-size: 1.95em;
    margin: -5px 0 0 0;
  }
}

.blinking_cursor {
  margin-left: 8px;
  -webkit-animation: 1s blink step-end infinite;
  -moz-animation: 1s blink step-end infinite;
  -ms-animation: 1s blink step-end infinite;
  -o-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite;
}
#hoop-laptop-sign,
#hoop-laptop {
  width: 90vw;
  @media only screen and (min-width: $mobile_breakpoint) {
    width: 700px;
  }
}
#shot-01-path,
#shot-02-path,
#shot-03-path {
  opacity: 0;
}
#screensaver-path {
  opacity: 0;
}
#screen {
  isolation: isolate;
}
#glare {
  opacity: .2;
  // mix-blend-mode: hard-light;
}
#text-connect {
  // mix-blend-mode: exclusion;
}


@-webkit-keyframes rotate {
    from { -webkit-transform: rotate(360deg); }
    to { -webkit-transform: rotate(0); }
}
@-moz-keyframes rotate {
    from { -moz-transform: rotate(360deg); }
    to { -moz-transform: rotate(0); }
}
@-ms-keyframes rotate {
    from { -ms-transform: rotate(360deg); }
    to { -ms-transform: rotate(0); }
}
@-o-keyframes rotate {
    from { -o-transform: rotate(360deg); }
    to { -o-transform: rotate(0); }
}
@keyframes rotate {
    from { transform: rotate(360deg); }
    to { transform: rotate(0); }
}

#spinning-svg-circle { 
  margin: 0%; 
  position: absolute;
  right: 0;
  transform: translate(-50%, 0);
  @media only screen and (min-width: $mobile_breakpoint) {
    transform: translateY(-100%);
  }
}

#circle { 
  position: relative; 
  padding-bottom: 100%; 
  overflow: hidden; 
  width: 150px; 
  height: 150px;
}

#circle text { 
  text-transform: uppercase;
  font-family: 'VT323', monospace;
  font-size: 11.5px; 
  color: var(--neon-green);
}

#circle svg { 
  position: absolute; left: 0; top: 0; 
  width: 100%; 
  height: 150px;


          animation-name: rotate;
          animation-duration: 30s;
  -webkit-animation-iteration-count: infinite;
     -moz-animation-iteration-count: infinite;
      -ms-animation-iteration-count: infinite;
       -o-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
     -moz-animation-timing-function: linear;
      -ms-animation-timing-function: linear;
       -o-animation-timing-function: linear;
          animation-timing-function: linear;

}
