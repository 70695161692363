html, body {margin:0; padding:0}

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 10px;
  font-family: 'IBM Plex Sans', sans-serif;
  background-color: var(--screen-bg-blue);
  // background-color: rgb(243, 243, 240);
  
  background-image: url(../images/tiny_dots.svg);
  // background-image: url(../images/tiny_dots_yellow.svg);
  background-size: 5px;
}
.mobile-only {
  display: block;
  @media only screen and (min-width: $mobile_breakpoint) {
    display: none;
  }
}
.desktop-only {
  display: none;
  @media only screen and (min-width: $mobile_breakpoint) {
    display: block;
  }
}
::-moz-selection { /* Code for Firefox */
  background-color: var(--computer-blue);
  color: var(--neon-green);
}

::selection {
  background-color: var(--computer-blue);
  color: var(--neon-green);
}